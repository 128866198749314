import React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

import { Container, Section } from "components/anti/grid/grid"
import { Card } from "components/anti/card/card"
import { FooterBrand } from "components/fostive/footer-brand/footer-brand"

import { replaceUnicode } from "src/utils/mixins"

import icBack from "assets/img/common/ic_back.png"
// import imgDummy1 from "assets/img/sample/img_dummy_1.jpg"

const Works = ({ location, pageContext }) => {
  return (
    <Layout footer={false} background="#9E9E9E">
      <SEO
        title={replaceUnicode(pageContext.data.name)}
        description={pageContext.data.description}
      />

      <Section className="text-white">
        <Container className="mw-xl">
          <div>
            <div className="mb-3 animated fadeInUp delayp1">
              <Link to={`/${location.pathname.split("/")[1]}`}>
                <img src={icBack} alt="back" width="32px" />
              </Link>
            </div>
            <div className="d-sm-flex justify-content-sm-between align-items-sm-center animated fadeInUp delayp2">
              <h2 className="custom-title-works">
                {replaceUnicode(pageContext.data.name)}
              </h2>
              <div
                className="d-none d-sm-flex align-items-center"
                style={{ fontSize: "20px" }}
              >
                <a
                  className="text-white mb-sm-down-2"
                  href={`https://instagram.com/${replaceUnicode(
                    pageContext.data.projectsTaxonomy.instagram
                  )}`}
                  target="blank"
                >
                  <i className={`fab fa-instagram mr-2`}></i>
                  <span>
                    {replaceUnicode(
                      pageContext.data.projectsTaxonomy.instagram
                    )}
                  </span>
                </a>
              </div>
            </div>
            <div className="mw-md animated fadeInUp delayp3">
              <p className="text-white-50">
                {replaceUnicode(pageContext.data.description)}
              </p>
            </div>
            {/* mobile version */}
            <a
              className="text-white d-block d-sm-none custom-text-instagram"
              href={`https://instagram.com/${replaceUnicode(
                pageContext.data.projectsTaxonomy.instagram
              )}`}
            >
              <i className={`fab fa-instagram mr-2`}></i>
              <span>
                {replaceUnicode(pageContext.data.projectsTaxonomy.instagram)}
              </span>
            </a>
          </div>
        </Container>

        <Container className="mw-xl mt-5 animated fadeInUp delayp4">
          <div className="row">
            {pageContext.data.projects.nodes.map((item, i) => {
              const headline = item.blocks.find(
                block => block.name === "acf/headline"
              )

              // const galleryBlock = item.blocks.find(
              //   gallery => gallery.name === "acf/gallery"
              // )

              // const gallery =
              //   galleryBlock &&
              //   galleryBlock.gallery &&
              //   galleryBlock.gallery &&
              //   galleryBlock.gallery.gallery &&
              //   galleryBlock.gallery.gallery[0].sourceUrl
              const featuredImage = item?.featuredImage?.mediaItemUrl

              return (
                <div key={i} className="col-12 col-sm-6 col-lg-4 mb-4">
                  <Card
                    key={i}
                    variant="overlay"
                    label={
                      headline && headline.headline && headline.headline.label
                    }
                    labelClassName="text-white mb-2"
                    title={replaceUnicode(item.title)}
                    titleClassName="h5"
                    img={featuredImage}
                    imgHeight="h-ratio-3-2"
                    link={`${location.pathname}/${item.slug}`}
                  />
                </div>
              )
            })}

            {(pageContext.data.projects.nodes === undefined ||
              pageContext.data.projects.nodes.length === 0) && (
              <>
                <strong className="d-block mx-auto">No data found!</strong>
              </>
            )}
          </div>
          <FooterBrand mobile={true} />
        </Container>
      </Section>
    </Layout>
  )
}

export default Works
